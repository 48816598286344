<template>
    <div>
        <div>登录中...</div>
        <!-- <div>data:{{ data }}</div> -->
        <!-- {{ url }} -->
        <!-- <div>url: {{ window.location }}</div> -->
    </div>
</template>

<script>
import { wxLogin } from "@/api/login";
import wx from "weixin-js-sdk";
import { apiCallback } from "../../utils";
export default {
    data() {
        return {
            code: "",
            url: window.location,
            data: "",
            // 这是登录表单的数据绑定对象
        };
    },
    mounted() {
        // 获取URL参数方法
        function getQueryVariable(variable) {
            var query = window.location.search.substring(1);
            var vars = query.split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return decodeURI(pair[1]);
                } // eslint-disable-line
            }
            return false;
        }
        this.code = this.$route.query.code;
        // wx.miniProgram.redirectTo({
        //     url: "/pages/login/login?mpcode=" + this.code,
        //     success: function () {
        //         console.log("成功跳回小程序");
        //     },
        //     fail: function () {
        //         console.log("跳转回小程序页面失败");
        //     },
        // });
        var that = this;
        var params = {};
        params.code = this.code;
        wxLogin(params)
            .then((res) => {
                let data = apiCallback(res);
                if (data) {
                    that.data = data;
                    const { userid, openid, token } = data;
                    if (userid) {
                        this.$store.commit("user/editToken", openid);
                        wx.miniProgram.reLaunch({
                            url:
                                "/pages/login/login?mpOpenid=" +
                                openid +
                                "&mpUserid=" +
                                userid +
                                "&mpAccess=" +
                                token,
                            success: function () {
                                console.log("成功跳回小程序");
                            },
                            fail: function () {
                                console.log("跳转回小程序页面失败");
                            },
                        });
                    }
                } else {
                    wx.miniProgram.reLaunch({
                        url: "/pages/login/login?mpUserid=0",
                        success: function () {
                            console.log("成功跳回小程序");
                        },
                        fail: function () {
                            console.log("跳转回小程序页面失败");
                        },
                    });
                }
            })
            .catch((err) => {
                wx.miniProgram.reLaunch({
                    url: "/pages/login/login?mpUserid=0",
                    success: function () {
                        console.log("成功跳回小程序");
                    },
                    fail: function () {
                        console.log("跳转回小程序页面失败");
                    },
                });
                that.$message.error(data.meta.msg);
            });
    },
};
</script>

<style lang="stylus" scoped></style>
